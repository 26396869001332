import React from "react"

import ContentPage from "../contentPage"
import { Link } from "gatsby";
import styles from "./index.module.css";

const MFCentralCASAPITermsOfUse = () => (
  <>
    <ContentPage title={'Terms of Use for availing MF Central’s CAS API services provided by MProfit'}>
		<p>
		Below are the terms of use ("Terms") governing the data fetching services from MFCentral (“Services”) offered by MProfit Software Private Limited ("MProfit") in partnership with MVest Services Private Limited ("MVest"), a wholly owned subsidiary of MProfit. By accepting these Terms, you acknowledge and agree to the following:
		</p>
			
		<ul style={{marginBottom: 0}}>
			<li>As a user, you commit to utilising the MProfit Platform in accordance with MProfit’s <TermsOfUseLink/>.</li>
			<li>MProfit, functioning as the third-party technology partner, facilitates the seamless retrieval of data from MF Central on behalf of MVest, an AMFI registered Mutual Fund Distributor (ARN-276970) that offers the Services.</li>
			<li>Your eligibility to access the Services is subject to your acceptance of MProfit’s commercial policies, including but not limited to the terms and conditions of our subscription plans outlined on MProfit’s website <MProfitWebsiteLink/> and other pricing details that can be requested by emailing MProfit at support@mprofit.in.</li>
			<li>You provide MVest explicit consent to fetch your requested Mutual Fund transaction details from MFCentral via the CAS API and add these details to your MProfit account. You further provide MVest explicit consent to retain the data fetched from MFCentral in your MProfit account for your usage, until you yourself permanently delete the data or the MProfit account.</li>
			<li>You acknowledge that to successfully complete your request to fetch the requested data from MFCentral, you will need to accurately provide the data required to fulfil such request, to MVest including but not limited to Permanent Account Number (PAN), Email Address, Contact Number and One Time Password (OTP).</li>
			<li>In the transaction data retrieved from MFCentral, certain transaction types may be normalised as per MProfit standards, maintaining the fundamental nature of the data. Further, any discrepancies observed in the retrieved data should be promptly reported to the MProfit team for resolution by writing an email to support@mprofit.in.</li>
			<li>MProfit puts in its best possible efforts to ensure the security of your portfolio data. You are further provided the option of enabling 2-factor authentication in your MProfit Cloud account for additional account security.</li>
			<li>You retain the option to edit or delete the data you have added in your MProfit account at any time.</li>
		</ul>

		<p></p>

		<p>Your consent to the above Terms grants you access to avail the Services.</p>

		<p>It is important to note that these Terms are subject to updates and any modifications will be communicated on the MProfit website <MFCentralCASAPITermsOfUseLink/>. You are responsible for staying informed about the latest version of these Terms.</p>

		<p>By providing your acceptance of the Terms in your MProfit account before accessing the Services for the first time, you confirm your understanding of these Terms and provide your explicit and recorded consent to MVest, for as long as you use the Services, to fetch your data from MFCentral in accordance with the above Terms. If you do not wish to accept these Terms, it is advised that you refrain from using the Services.</p>
    </ContentPage>
  </>
)

const TermsOfUseLink = () => <Link to="/terms-of-use">Terms of Use</Link>

const MFCentralCASAPITermsOfUseLink = () => <Link to="/mfcentral-cas-api-terms">[www.mprofit.in/mfcentral-cas-api-terms]</Link>

const MProfitWebsiteLink = () => <Link to="/">[www.mprofit.in]</Link>

export default MFCentralCASAPITermsOfUse
