import React from "react"
import Layout from "../components/flayout"
import MFCentralCASAPITermsOfUse from "../components/mfCentralCASAPITermsOfUse"

const CASAPITermsPage = () => (
  <Layout darkHeader={true} seoFields={seoFields} showAnnouncement={false}>
      <MFCentralCASAPITermsOfUse />
  </Layout>
)

const seoFields = {
  description: "Read MProfit's terms of use for using the MFCentral CAS API.",
  // lang,
  // meta,
  // keywords,
  title: "MProfit MFCentral CAS API Terms of Use" 
}

export default CASAPITermsPage
